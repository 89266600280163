import { getHeaderHeight } from "../functions/header-height.js";
import { burger } from "../functions/burger.js";

document.addEventListener("DOMContentLoaded", function () {
  const header = document.querySelector(".header");
  getHeaderHeight();
  const burgerWrapper = document.querySelector(".burger__wrapper");
  const burger = document.querySelector(".burger");

  burgerWrapper?.addEventListener("click", (event) => {
    event.preventDefault();
    burger.click();
  });

  window.addEventListener("scroll", function () {
    if (window.scrollY >= 60) {
      header.classList.add("topheader");
    } else {
      header.classList.remove("topheader");
    }
  });

  window.addEventListener("resize", function () {
    getHeaderHeight();
  });

  function scrollUp() {
    const scrollUp = document.getElementById("scroll-up");
    if (this.scrollY >= 500) scrollUp.classList.add("show-scroll");
    else scrollUp.classList.remove("show-scroll");
  }

  window.addEventListener("scroll", scrollUp);
});
