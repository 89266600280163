document.addEventListener("DOMContentLoaded", () => {
  if (document.documentElement.clientWidth <= 992) {
    const speakersSlider = new Swiper(".speakers-slider", {
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 14,
      speed: 1800,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  }
});
